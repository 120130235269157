.chat-nav{
    background-color: #4056F4; 
    padding: 10px; 
    text-align: center
}
.chat-nav > a{
    color: white;
    text-decoration: none;
    margin: 0 20px;
    font-size: 1.1em;
    
}

.chatHeader{
    background-color: #333;
    color: #fff; 
    padding: 20px 0; 
    text-align: center;
    position: relative;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.chatHeader > div:last-child {
    position: absolute;
    right: 10px;
  }

  #features>div>div{
        width: 29%; 
        background-size: cover;
        background-position: center;
        color: black; padding: 20px;
        margin: 20px 0; 
        border-radius: 10px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); 
        display: flex; 
        flex-direction: column;
        align-items: center; 
  }