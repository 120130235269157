.UpcomingGames{
    margin: 0px 10px 0px auto;
    width: 300px;
}
.SportsHome{
    display: flex;
    width: 100%;
}
.GenericGame{
    border: 1px black solid;
    color: white;
    font-size: 20px;
}
.LakerGame{
    background-color: #FDB927;
}
.RazorbackGame{
    background-color: #9D2235; 
}
.CowboyGame{
    background-color: #003594;
}
.NewsHeader{
    color: white;
    font-size: 32px;
    font-family: "Heebo";
    font-weight: black;

}
.NewsDiv{
    margin-top: 10px;
    margin-left: 10px;
    padding: 10px 0px;
}
.NewsSection{
    display: flex;
    padding-top: 10px;
    
}
.SportsHomeNewsWrapper{
    float: left;
    flex-grow: 1;
}
.SecondaryNewsDetail{
    display:flex;
    align-items: center;
    
}

.SecondaryNewsDetail >p, .SecondaryNewsDetail a{
    padding-right: 10px;
    color:gray;
}

.SecondaryNewsDetail > a{
    text-decoration:underline;
}
.MainNewsSports{
    width: 50%;
    border-right: 1px black dashed;
    font-family: "Heebo";
    font-weight: black;
    font-size: 18px;
}
.SecondaryNewsSports{
    width: 50%;
    font-family:'Roboto-Medium', sans-serif;
    
}
@media screen and (max-width: 480px) {
    .SportsHomeNewsWrapper{
        margin: auto;
        flex-wrap: wrap;
        display:flex;
        width: 100%;
    }
    
    .NewsSection{
        flex-wrap: wrap;
    }
    .NewsDiv, .MainNewsSports, .SecondaryNewsSports{
        width: 100%;
        margin-left: 0px;
        margin: auto;
    }
    .MainNewsSports{
        border-right: none;
        border-bottom: 1px black dashed;
        padding: 0px 10px;
    }
    .SportsImgWrapper > img{
        height: auto;
        width: 100%;
    }
    
  }
.MainNewsSports  > div> p{
    margin: 10px;
}

.SportsImgWrapper{
    text-align: center;
}
.SecondaryNewsSports > div {
    padding:10px 0px;
}
.SecondaryNewsSports > div > a{
    padding:10px 0px;
    text-decoration: none;
    color:black;
    font-size:16px;
}

.SecondaryNewsSports > div > a:hover{
    text-decoration:underline;
}