.HomeBoard{
    margin-right: 0px;
    display: grid;
    margin-left: AUTO;
    align-content: center;
}
@import url('https://fonts.cdnfonts.com/css/campton');
.HomeBoard p{
    font-size:32px;
    color:#E55451;
    margin: 10px 0px;
    font-family: Campton, sans-serif;
    align-content: center;
}

.Greeting{
    margin: 10px auto !important;
     width:90%;
     padding:10px;
     color:white !important;
     background-color:#E55451;
     border-radius: 10px;
     box-shadow: rgba(0, 0, 0) 2.95px 2.95px 2.6px;
}
.Greeting div img{
    border-radius: 50% 50% !important;
}
.Greeting > div > div{
    width: 75%;
}
.HomeBoard div{
    display: flex;
    align-items: center;
}
.HomeBoard .summaryButton{
    margin-left: 0px;
    padding: 0px 10px;
    width: fit-content;
}
.HomeWrapper{
    color: black;
    margin: 50px 300px;
    display: flex;
    flex-wrap: wrap;
}
.ProjectDisplay{
    width: 31%;
    margin-left: 0; 
    float: left;
    padding: 0 0.75rem;
}
.ProjectContainer{
    width: 95%;
    padding: 0 1.5rem;
    margin: 0 auto;
    max-width: 1280px;
}
.ProjectContainer > div{
    margin-left: -0.75rem;
    margin-right: -0.75rem;
}
@media screen and (max-width: 480px) {
    .HomeWrapper{
        margin:10px auto;
    }
    .ProjectDisplay{
        width: 95%;
        padding: 0;
        margin: auto;
        float: none;
    }
    .ProjectContainer{
        padding: 0;
    }
    .ProjectContainer > div {
         margin-left: 0; 
         margin-right: 0;
    }
    .Greeting > div > div {
         width: 100%;

    }
    .HomeBoard div{
        display: flex;
        align-items: center;
        margin: auto;
    }
    .HomeBoard p{
        text-align: center;
    }
    .HomeWrapper img{
        max-width: 300px;
        margin: auto;
    }
    
  }
.HomeWrapper img{
    border-radius: 10% 20%;
}

.ProjectCard{
    height: 400px;
    margin: 0.5rem 0 1rem 0;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 5px;
    position: relative;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
    font-family: Campton, sans-serif;
    font-size: 18px;
    color:#E55451;
    transform: perspective(1000px) rotateY(var(--rotate-y, 0)) translateY(var(--translate-y,0));
    transform-style:preserve-3d;
    transition:250ms;
}
.ProjectCard.flip{
--rotate-y:180deg;
}
.ProjectCard > div{
    padding:10px;
    display: flex;
    align-items: center;
    background-color: white;
}
.ProjectCard > div > p{
    width: 90%;

}
.ProjectCard > div > div{
    cursor: pointer;
    float: right;
    margin-left: auto;
    margin-right: 10px;
    font-size: 32px;

}
.ProjectCard > img{
    border-radius: 5px 5px 0px 0px;
}
.ProjectCardBackButton{
    color: black;
    cursor: pointer;
}

.ProjectDisplay .Back{
    transform: rotateY(180deg);
}


.Back > div{
    transform: rotateY(180deg);
}
.ProjectCard h2{
    font-size: 22px;
}

/* Table Container */
.table-container {
    max-width: 900px;
    margin: 0 auto;
    overflow-x: auto;
    font-family: Arial, sans-serif;
    color: #333;
    padding: 20px;
}

/* Table styles */
.modern-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
}

.modern-table th,
.modern-table td {
    padding: 12px 15px;
    text-align: left;
}

.modern-table th {
    background-color: #E55451;
    color: #fff;
    font-size: 16px;
    letter-spacing: 1px;
}

.modern-table td {
    border-top: 1px solid #f2f2f2;
    font-size: 14px;
}

.modern-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.modern-table tr:hover {
    background-color: #f1f1f1;
}

.modern-table td:first-child {
    font-weight: bold;
}



/* Add responsiveness */
@media (max-width: 768px) {
    .modern-table {
        font-size: 12px;
    }

    .modern-table th,
    .modern-table td {
        padding: 10px;
    }
}
