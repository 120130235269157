.recipe-wrapper{
    display: flex;
    flex-wrap: wrap;
}
body{
    margin:0px;
}
html { font-family: serif; }
.recipe-individual{
    background-color: #ebe1e8;
    margin-bottom: 10px;
    border-radius: 10px;
    width:440px;
    margin-bottom: 20px;
    margin-left:50px;
    box-shadow: rgba(0, 0, 0) 2.95px 2.95px 2.6px;
}
.viewWrap{
    display: flex;
    flex-wrap: wrap;
    box-shadow: 'none'    
}
.is-selected-recipe{
    box-shadow: #4056F4 2.95px 2.95px 2.95px 2.95px;
}
.inputBox{
    height: 30px;
    width: 200px;
    background: white;
    -webkit-box-shadow: inset 0 1px 3px 0 rgba(0,0,0,.08);
    -moz-box-shadow: inset 0 1px 3px 0 rgba(0,0,0,.08);
    box-shadow: inset 0 1px 3px 0 rgba(0,0,0,.08);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    padding: 0;
    font-size: 16px;
    color: #666;
    -webkit-transition: all .4s ease;
    -o-transition: all .4s ease;
    -moz-transition: all .4s ease;
    transition: all .4s ease;
    outline: none;
    margin-right: 15px;
    border: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    font-family: inherit;
}

label{
    font-size: 16px;
    color: #555;
    text-transform: capitalize;
    display: block;
    margin-bottom: 5px;
}

.selected-Item{
    text-decoration: line-through;
}
.singleIngredientItem{
    border-bottom:1px gray solid;
    cursor:pointer;
}

@media screen and (min-width: 480px) {
    .singleIngredientItem:hover{
        background-color:darkgray;
    }
  }
.textAreaAdd{
    height: 200px;
    width: 100%;
}
.addInput{
    width: 100%;
}
.searchBarDiv{
    background-color: #ebe1e8;
    color: black;
    margin:10px 50px;
    display:flex;
    flex-wrap: wrap;
    align-items: center;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0) 2.95px 2.95px 2.6px;
}
.shoppingListDiv{
    background-color: #ebe1e8;
    color: black;
    margin:10px 50px;
    
    align-items: center;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0) 2.95px 2.95px 2.6px;
    padding-left: 10px;
    padding-bottom: 10px;
}

.CustomRollDiv{
    display: block;
    padding: 10px;
}
.CustomRollDiv .recipe-individual{
    margin:10px auto;

}
@media screen and (max-width: 480px) {
    .shoppingListDiv{
        margin: auto;
        width: 100%;
    }
    .filterDiv button{
        right: 20px;
        top: 15px;
        position: absolute;
    }
    .inputDiv{
        width: 100%;
    }
    .inputSingleForm{
        width: 100%;
    }
  }
  .shoppingListHeader{
    display:flex;
    flex-wrap: wrap;
    align-items: center;
  }
.summaryHeaderText{
    padding-left: 10px;
}
.filterDiv{
    /* #798897*/
    align-items: end;
    margin: 10px 30px auto auto;
    display: flex;
    flex-wrap: wrap;
    padding-left: 10px;
    padding-bottom: 10px;
}
.recipe-title{
    color:white;
    margin-top: 0px;
    margin-bottom:0px;
    background-color:#E55451;
    border-radius: 10px 10px 0px 0px;
    padding:5px 10px;
    margin-left:0px;
    font-size:32px;
    border-bottom:black 1px solid;
    margin-bottom: 0px;
}
@media screen and (max-width: 480px) {
    .recipe-title{
        font-size:24px;
        max-height: 50px;
    }
  }
.recipe-description{
    color:black;
    padding:5px 10px;
    font-size:18px;
    height: 150px;
    margin-top:0px;
    /*width:inherit;*/
}
.RandomFoodDiv{
    width: 100%;
    background-color: #ebe1e8;
    font-size:32px;
    height: 400px;
    border-radius: 0px 0px 10px 10px;
    box-shadow: rgba(0, 0, 0) 2.95px 2.95px 2.6px;
}
.CustomRollDiv .summaryButton{
    padding-bottom: 10px;
    margin-left: 0px;
}
.Body{
    display:flex;
    flex-wrap: wrap;
}
.recipe-summary{
    width:500px;
    margin-bottom:50px;
    
}
.formViewWrapper{
    width: 100%;
    padding: 10px;
    padding: 10px;
    width: 90%;
    margin: auto;
}
@media screen and (min-width: 480px) {
    .formViewWrapper{
        width:100%;
    }
    .inputDiv{
        padding: 10px 0px;
    }
    .textAreaDiv{
        height: 400px;
        width: 500px;
        padding-left: 10px;
    }
    .textAreaAdd{
        height: 300px;
        width: 500px;
    }
  }
@media screen and (max-width: 480px) {
    .recipe-summary{
        width:300px;
    }
    .searchBarDiv{
        width:350px;
        margin: 10px auto;
    }
  }
  .summary{
    width: 70%;
  }
.recipe-summary-box{
    background-color: #ebe1e8;
    min-height: 300px;
    display: flex;
    border-radius: 0px 0px 10px 10px;
    box-shadow: rgba(0, 0, 0) 2.95px 2.95px 2.6px;
}
.recipe-view-box{
    background-color: #ebe1e8;
    min-height: 300px;
    border-radius: 0px 0px 10px 10px;
    box-shadow: rgba(0, 0, 0) 2.95px 2.95px 2.6px;
}

.Overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    opacity: 1;
    visibility: visible;
    bottom: 0;
    transition: opacity 0.3s ease-out 0s, visibility 0.3s ease-out 0s;
    background-color: rgba(0, 0, 0, 0.3);

}
.summaryItem{
    padding:10px;
    margin-top: 0px;
}
.summaryIngredient{
    padding:15px;
    margin-top: 0px;
    white-space: break-spaces;
    overflow-wrap: break-word;
}

.summaryDivButton{
    height: 24px;
    margin: 10px 30px auto auto;
}
.summaryButton{
    color: #4056F4;
    background-color: white;
    border-radius: 5px;
    align-items: center;
    text-align: center;
    box-sizing: border-box;
    display: grid;
    border: 2px #4056F4 solid;
    width: 100px;
    font-size: 18px;
    cursor: pointer;
    padding:0px;
    height: 50px;
    margin-left: 10px;
    text-decoration: none;
}

@media screen and (min-width: 480px) {
    .summaryButton:hover{
        background-color: #4056F4;
        color: white;
    }
  }


.summaryButton:active {
    background-color: #4056F4;
    box-shadow: 0 5px #666;
    color: white;
    transform: translateY(4px);
  }
  
.viewButton{
    width: 60px;
}
.isSelected{
    background-color: #4056F4;
    color: white;
}

.recipeButtonWrapper{
    display:flex;
}
.summaryHeader{
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    background-color:#E55451;
    color:white;
    /* justify-content: center;*/
}
@media screen and (max-width: 480px) {
    .recipe-wrapper{
        margin: auto;
        width:350px;
    }
  }

@media screen and (max-width: 480px) {
    .recipe-individual{
        width:350px;
        margin-left:0px;
    }
  }